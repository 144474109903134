import styled from 'styled-components'
import theme from '~/styles/theme'

export const SocialButton = styled.a`
  background-color: ${theme.colors.cream};
  width: 4rem;
  height: 4rem;
  border-radius: 2rem;
  border: none;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 1rem;
  overflow: hidden;
  position: relative;

  &:before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    transition: background 150ms ease-in-out;
  }

  &:hover {
    &:before {
      background: rgba(0,0,0,0.05);
    }
  }
`