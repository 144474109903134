import React from 'react'
import theme from '~/styles/theme'

import { SocialButton } from './style'

import {
  Box,
  Spacer,
  Icon,
  Heading
} from '~/styles/components'

const SocialButtons = ({ heading, message, url }) => {

  const twitterLink = `https://twitter.com/intent/tweet?text=${message}&url=${url}`
  const facebookLink = `https://www.facebook.com/sharer/sharer.php?u=${url}`

  return (
    <div>
      <Heading level="4">
        {heading}
      </Heading>
      <Spacer size="xs" />
      <Box display="flex">
        <SocialButton href={facebookLink} target="_blank">
          <Icon
            name="facebook"
            size="2rem"
            color={theme.colors.black}
          />
        </SocialButton>
        <SocialButton href={twitterLink} target="_blank">
          <Icon
            name="twitter"
            size="2rem"
            color={theme.colors.black}
          />
        </SocialButton>
      </Box>
    </div>
  )
}

export default SocialButtons