import React from 'react'
import PropTypes from 'prop-types'

import {
  Box,
  Spacer,
  Container,
  Row,
  Col,
  Heading,
  Bullet,
  Paragraph,
  Link,
  Icon
} from '~/styles/components'

const LinksBlock = ({
  heading = 'Links & resources',
  subheading,
  links = []
}) => {
  return (
    <Box>
      <Spacer size={{ _: 'md', lg: 'lg' }} />
      <Container>
        <Row>
          <Col lg={6} marginTop={{ _: 0, lg: '-0.75rem' }}>
            <Heading level="2">
              {heading}
            </Heading>
            <Paragraph large>
              {subheading}
            </Paragraph>
            <Spacer size={{ _: 'sm', lg: 0 }} />
          </Col>
          <Col lg={6}>
            {links.map((link, i) =>
              <React.Fragment key={i}>
                <Heading level="4">
                  <Bullet
                    bg={['yellow', 'purple', 'green'][i]}
                    color={['black', 'white', 'white'][i]}
                    icon
                  >
                    <Icon name="link" />
                  </Bullet>
                  <Link to={link.url} underlined target="_blank">
                    {link.text}
                  </Link>
                </Heading>
                {link.description && link.description.length > 0 &&
                  <Paragraph>
                    {link.description}
                  </Paragraph>
                }
                <Spacer size="xs" />
              </React.Fragment>
            )}
          </Col>
        </Row>
      </Container>
      <Spacer size={{ _: 'md', lg: 'lg' }} />
    </Box>
  )
}

LinksBlock.propTypes = {
  heading: PropTypes.string,
  subheading: PropTypes.string,
  links: PropTypes.arrayOf(
    PropTypes.shape({
      url: PropTypes.string,
      text: PropTypes.string,
      description: PropTypes.string,
    })
  ),
}

export default LinksBlock
