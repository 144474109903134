import React, { useState, useEffect } from 'react'
import { graphql } from 'gatsby'
import queryString from 'query-string'

import { fetchQuizResult } from '~/utils/firebase-client'

import Metadata from '~/components/Metadata'
import BigMomentsBlock from '~/components/BigMomentsBlock'
import WhatsHealthyBlock from '~/components/WhatsHealthyBlock'
import LinksBlock from '~/components/LinksBlock'
import SocialButtons from '~/components/SocialButtons'

import loveTwitterImage from '~/assets/images/the-giver.png'
import freedomTwitterImage from '~/assets/images/the-independent.png'
import powerTwitterImage from '~/assets/images/the-high-flyer.png'
import securityTwitterImage from '~/assets/images/the-sensible.png'

import {
  Box,
  Container,
  Spacer,
  Heading,
  Row,
  Col,
  Highlight,
  DripPattern,
  Character,
  Divider,
  Button,
  Icon,
  Markup
} from '~/styles/components'

import { mapCraft } from './mappings'

const QuizResultTemplate = ({ location, data }) => {
  const { entry } = mapCraft(data.craft)
  const { metadata, talkTypes, links } = entry

  const { id } = queryString.parse(location.search)
  const [result, setResult] = useState(null)
  const [loading, setLoading] = useState(true)

  const url = location.href ? location.href : '';
  
  useEffect(() => {
    fetchQuizResult(id)
      .then(res => {
        setResult(res)
        setLoading(false)
      })
  }, [])

  let profileColor, talkType, nameSplit, twitterImage, shareMessage
  if (result) {
    talkType = talkTypes[result.profile]

    profileColor = {
      love: 'pink',
      security: 'green',
      freedom: 'purple',
      power: 'yellow',
    }[result.profile]
    
    shareMessage = {
      love: `When it comes to money styles, I'm the Giver. Find out yours by completing the You, Me & Money quiz!`,
      security: `When it comes to money styles, I'm the Sensible One. Find out yours by completing the You, Me & Money quiz!`,
      freedom: `When it comes to money styles, I'm the Independent. Find out yours by completing the You, Me & Money quiz!`,
      power: `When it comes to money styles, I'm the High-Flyer. Find out yours by completing the You, Me & Money quiz!`
    }[result.profile]
  
    twitterImage = {
      love: loveTwitterImage,
      security: securityTwitterImage,
      freedom: freedomTwitterImage,
      power: powerTwitterImage
    }[result.profile]

    nameSplit = talkType.name.length > 12 ? talkType.name.split(' ') : [talkType.name]
  }

  return (
    <Box>
      <Metadata
        title="Quiz result"
        twitterImage={twitterImage}
        {...metadata}
      />
      {!result &&
        <Container>
          <Spacer size={{ _: 'md', lg: 'lg' }} />
          <Box
            width="100%"
            height="100%"
            display="flex"
            justifyContent="center"
            alignItems="center"
            typography="headingMedium"
          >
            {loading ? 'Loading quiz result...' : 'There was an error'}
          </Box>
          <Spacer size={{ _: 'md', lg: 'lg' }} />
        </Container>
      }
      {result && !loading &&
        <>
          <DripPattern
            color="cream"
          />
          <Container>
            <Spacer size={{ _: 'md', lg: 'lg' }} />
            <Row>
              <Col xs={12} sm={7}>
                <Heading
                  level="5"
                  fontWeight="normal"
                  borderBottom="1px dashed #00000050"
                  display="inline-block"
                >
                  Your results
                </Heading>
                <Heading level="2">
                  <i>When it comes to</i> <b>talking money</b> <i>you’re...</i>
                </Heading>
                <Heading level="1">
                  {nameSplit.map((word, i) =>
                    <React.Fragment key={i}>
                      <Highlight color={profileColor}>{word}</Highlight>
                    </React.Fragment>
                  )}
                </Heading>
                <Spacer size={{ _: 'sm', sm: 'xxs' }} />
              </Col>
              <Col xs={12} sm={5}>
                <Character
                  name={result.profile}
                  position="relative"
                  width="100%"
                  paddingBottom="100%"
                />
              </Col>
            </Row>
          </Container>
          <Container>
            <Spacer size="md" />
            <Row>
              <Col xs={12} lg={6}>
                <Markup
                  typography="paragraphLarge"
                  margin="0"
                  html={talkType.talkingPoints}
                />
                <Spacer size="sm" />
                <SocialButtons
                  heading="Share your results!"
                  url={url}
                  message={shareMessage}
                />
                <Spacer size="md" />
                <Row>
                  <Col xs={12} lg={10}>
                    <Button
                      outline
                      wide
                      color="purple"
                      to="/quiz"
                    >
                      Redo the quiz
                      <Icon name="arrow" />
                    </Button>
                  </Col>
                </Row>
              </Col>
            </Row>
            <Spacer size="md" />
          </Container>
          <Divider display={{ _: 'block', lg: 'none' }} />
          <Container>
            <Spacer size={{ _: 'md', lg: 'lg' }} />
            <Heading level="3">
              What does this mean for your partner?
            </Heading>
            <Spacer size="sm" />
            <Row>
              <Col xs={12} lg={6}>
                <Markup
                  typography="paragraphLarge"
                  margin="0"
                  html={talkType.partnerTalkingPoints}
                />
              </Col>
            </Row>
            <Spacer size={{ _: 'md', lg: 'lg' }} />
          </Container>
          <Divider display={{ _: 'block', lg: 'none' }} />
          <BigMomentsBlock
            heading="Big moments"
            subheading="Are you moving in with someone, opening a joint account, or starting a joint loan or investment?"
          />
          <Divider display={{ _: 'block', lg: 'none' }} />
          <LinksBlock
            links={links}
          />
          <Spacer size={{ _: 'md', lg: 'lg' }} />
          <WhatsHealthyBlock />
        </>
      }
    </Box>
  )
}

export default QuizResultTemplate

export const query = graphql`
query QuizResultTemplateQuery($id: Craft_QueryArgument) {
  craft {
    entry(id: [$id]) {
      ... on Craft_quizResult_quizResult_Entry {
        metadata {
          ... on Craft_metadata_BlockType {
            title: metadataTitle
            description: metadataDescription
            keywords: metadataKeywords
            noFollow: metadataNoFollow
            noIndex: metadataNoIndex
          }
        }
        freedomTalkType {
          ... on Craft_freedomTalkType_BlockType {
            name: talkTypeName
            talkingPoints
            partnerTalkingPoints
          }
        }
        loveTalkType {
          ... on Craft_loveTalkType_BlockType {
            name: talkTypeName
            talkingPoints
            partnerTalkingPoints
          }
        }
        securityTalkType {
          ... on Craft_securityTalkType_BlockType {
            name: talkTypeName
            talkingPoints
            partnerTalkingPoints
          }
        }
        powerTalkType {
          ... on Craft_powerTalkType_BlockType {
            name: talkTypeName
            talkingPoints
            partnerTalkingPoints
          }
        }
        links {
          ... on Craft_links_BlockType {
            text: linkText
            url: linkUrl
            description: linkDescription
          }
        }
      }
    }
  }
}
`